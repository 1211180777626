import { mapState, mapActions } from 'vuex'

export const layoutComputed = {
  ...mapState('layout', {
    loader: (state) => state.loader
  })
}

export const layoutMethods = mapActions('layout', ['changeLoaderValue'])

export const notificationMethods = mapActions('notification', ['success', 'error', 'clear'])
