export const state = {
  loader: true
}

export const mutations = {
  LOADER(state, loader) {
    state.loader = loader
  }
}

export const actions = {
  changeLoaderValue({ commit }, { loader }) {
    commit('LOADER', loader)
  }
}
