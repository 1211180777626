export default [
  {
    path: '/',
    name: 'landing',
    component: () => import('./views/landing')
  },
  {
    path: '/legal/imprint',
    name: 'imprint',
    component: () => import('./views/imprint')
  },
  {
    path: '/legal/terms-of-use',
    name: 'tos',
    component: () => import('./views/terms-of-use')
  },
  {
    path: '/legal/community-license',
    name: 'tos',
    component: () => import('./views/community-license-agreement')
  },
  {
    path: '/docs/prices-etc',
    name: 'prices_etc',
    component: () => import('./views/price-register')
  },
  {
    path: '/legal/privacy',
    name: 'privacy',
    component: () => import('./views/privacy-policy')
  },
  {
    path: '/legal/dpa',
    name: 'dpa',
    component: () => import('./views/dpa')
  },
  {
    path: '/legal/eula',
    name: 'eula',
    component: () => import('./views/eula')
  },
  {
    path: '/legal',
    name: 'legal',
    component: () => import('./views/imprint')
  },
  {
    path: '/maintenance',
    name: 'maintenance',
    component: () => import('./views/utility/maintenance')
  },
  {
    path: '/500',
    name: '500',
    component: () => import('./views/utility/500')
  },
  {
    path: '/404',
    name: '404',
    component: require('./views/utility/404').default,
  },
  // Redirect any unmatched routes to the 404 page. This may
  // require some server configuration to work in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  {
    path: '*',
    redirect: '/',
  }
]
