import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'

import i18n         from '@/i18n';
import VueMask from 'v-mask'
import VueRouter from 'vue-router'
import vco from "v-click-outside"
import router from './router/index'
import Scrollspy from 'vue2-scrollspy';

import "@/design/index.scss";

import store from '@/state/store'

import App from './App.vue'

//import i18n from './i18n'

Vue.use(VueRouter)
Vue.use(vco)
Vue.use(Scrollspy);
const VueScrollTo = require('vue-scrollto')
Vue.use(VueScrollTo)
Vue.config.productionTip = false;

Vue.use(BootstrapVue)
Vue.use(VueMask)

/*
  Do not use i18n at this time
 */
new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
